.text-gray-80 {
  color: #414147;
}

.team-mobile {
  width: 100%;
  padding-left: 6.71%;
  padding-right: 6.71%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
  margin-bottom: 5vw;
}

.team-member-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
  width: 100%;
}

.team-member-image-mobile {
  width: 64.103vw;
}

.team-member-description-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
}

.team-member-title-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.026vw;
}

.team-member-achivements-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  padding-left: 6.71%;
}

.member-achivement-mobile {
  text-align: justify;
}
