.solution-mobile {
  padding: 0 6.15%;
  margin-top: 8vw;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6.154vw;
}

.solution-content-mobile {
  display: flex;
  flex-direction: column;
  gap: 5.5vw;
  text-align: justify;
}

.solution-levels-img-mobile {
  width: 87.795vw;
  margin: auto;
}

.solution-steps-img-mobile {
  width: 43.487vw;
  margin: auto;
}

.read-more-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.text-read-more-mobile {
  display: flex;
  align-items: center;
  height: auto;
}

.no-line-height-mobile {
  line-height: 5.128vw !important;
  margin-bottom: 0 !important;
}

.arrow-read-more-mobile {
  width: 5.128vw;
  height: 5.128vw;
}

.line-read-more-mobile {
  width: 102%;
  height: 0.641vw;
  background: #96c1c6;
  border-radius: 0.641vw;
}

.read-more-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.read-more-mobile:visited,
.read-more-mobile:hover,
.read-more-mobile:active {
  text-decoration: none !important;
}
