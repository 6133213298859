.general-approach-risk-assessment-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 8vw;
  margin-bottom: 6vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  text-align: justify;
}

.risk-assessment-joint-content-mobile {
  text-align: start;
  width: 100%;
}

.risk-assessment-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vw;
  align-self: stretch;
}

.risk-assessment-content-title-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  align-self: stretch;
}

.risk-assessment-list-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  padding-left: 3.5vw;
}

.risk-assessment-element-divider-mobile {
  width: 100%;
  height: 0.4vw;
  background: #96c1c6;
}

.risk-assessment-figure-image-mobile {
  width: 87.692vw;
  margin: auto;
}
