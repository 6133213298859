.general-approach-localization-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 7vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  text-align: justify;
}

.localization-joint-content-mobile {
  text-align: start;
  width: 100%;
}

.localization-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  align-self: stretch;
}

.localization-content-title-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  align-self: stretch;
}

.localization-list-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  padding-left: 3.5vw;
}

.localization-element-divider-mobile {
  width: 100%;
  height: 0.4vw;
  background: #96c1c6;
}
