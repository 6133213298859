.actis-content-mobile {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin: 8vw 0;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6vw;
  text-align: justify;
}

.figure-gif-actis-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  margin: 2vw auto;
}

.video-actis-mobile {
  width: 100%;
}
