.results-container {
  margin-top: 6vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4vw;
  text-align: justify;
}

.invalid-result {
  margin-top: 1.5vw;
  text-align: center;
}
