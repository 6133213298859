.why-part-2-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 3vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vw;
  text-align: justify;
}

.content-part-2-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vw;
}

.content-figure-2-desktop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.text-figure-2-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vw;
  width: 38.889vw;
}

.list-figure-2-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
}

ol > li::marker {
  font-weight: bold;
}

.figure-2-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.video-figure-2-desktop {
  width: 41.667vw;
}

.text-gray-80 {
  color: #414147;
}
