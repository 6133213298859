.legal-mentions-content-tablet {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 7vw;
  margin-bottom: 7vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5vw;
  text-align: justify;
}

.legal-mentions-section-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
}
