.support-tablet {
  padding: 0 6.71%;
  width: 100%;
  margin-top: 4vw;
  margin-bottom: 4vw;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 2.878vw;
}

.support-title-tablet {
  gap: 1vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.support-divider-tablet {
  width: 100%;
  height: 0.3vw;
  background: #96c1c6;
  border-radius: 0.3vw;
}

.logos-container-tablet-1 {
  display: flex;
  align-items: center;
  gap: 19.108vw;
}

.logos-container-tablet-2 {
  display: flex;
  align-items: center;
  gap: 16.288vw;
}

.logo-image-1-tablet {
  height: 9vw;
}

.logo-image-2-tablet {
  height: 5.12vw;
}

.logo-image-3-tablet {
  height: 6.561vw;
}

.logo-image-4-tablet {
  height: 6.197vw;
}

.logo-image-5-tablet {
  height: 9vw;
}

.logo-image-6-tablet {
  height: 7.194vw;
}
