.navbar-tablet {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 100 !important;
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
  padding: 5.755vw 6.71% !important;
}

.navbar-logo-img-tablet {
  height: 5.567vw;
}

.navbar-menu-button-tablet {
  height: 5.567vw;
}

.navbar-menu-button-cont-tablet {
  outline: none !important;
  border: 0 !important;
}

.navbar-menu-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  gap: 0.2vw;
  width: 66%;
  margin-left: auto;
  background-color: #181933 !important;
  color: #fff !important;
  padding: 2vw 0 !important;
  border-radius: 0.6vw !important;
  overflow: hidden !important;
}

.dropdown-tablet .dropdown-menu {
  background-color: #181933 !important;
  padding: 0 !important;
  border-radius: 0.2vw !important;
  border: none;
}

.dropdown-tablet .dropdown-toggle {
  color: #fff !important;
  padding: 1vw 3vw !important;
  overflow: hidden !important;
  width: 100%;
}

.dropdown-items-tablet {
  padding: 1vw 5vw !important;
  color: #fff !important;
  width: 100%;
}

.navbar-item-tablet,
.navbar-dropdown-item-tablet {
  padding: 1vw 4vw !important;
  width: 100%;
}

.navbar-dropdown-item-tablet .dropdown-toggle {
  padding-left: 0 !important;
}

.navbar-item-tablet:hover,
.dropdown-items-tablet:hover {
  background-color: #96c1c6 !important;
}

.navbar-item-active-tablet {
  background-color: #96c1c6 !important;
}
