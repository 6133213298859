.translocator-tablet {
  padding: 0 6.71%;
  margin-top: 4vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
}

.text-translocator-tablet {
  width: 100%;
  text-align: justify;
}

.read-more-tablet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.text-read-more-tablet {
  display: flex;
  align-items: center;
  height: auto;
}

.no-line-height-tablet {
  line-height: 2.878vw !important;
  margin-bottom: 0 !important;
}

.arrow-read-more-tablet {
  width: 2.878vw;
  height: 2.878vw;
}

.line-read-more-tablet {
  width: 102%;
  height: 0.3vw;
  background: #96c1c6;
  border-radius: 0.3vw;
}

.read-more-mobile:visited,
.read-more-mobile:hover,
.read-more-mobile:active {
  text-decoration: none !important;
}

.translocator-gif-tablet {
  width: 74.94vw;
  height: auto;
  margin: auto;
}
