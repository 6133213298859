.products-section-water-gas-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 1.667vw;
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  gap: 3.472vw;
}

.products-description-water-gas-desktop {
  display: flex;
  width: 52.083vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.333vw;
  text-align: justify;
  margin-top: 1.111vw;
}

.product-description-water-gas-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.222vw;
  align-self: stretch;
}

.products-images-water-gas-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.778vw;
}

.product-image-water-gas-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.image-water-gas-desktop {
  width: 27.778vw;
}

.description-image-water-gas-desktop {
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
