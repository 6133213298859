.hero {
  position: relative;
}

.image-hero {
  width: 100%;
}

.text-box-hero {
  position: absolute;
  left: 8.33%;
  top: 26.34%;
  display: flex;
  width: 59vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
}

.title-box-hero {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.title-hero {
  line-height: 2.917vw !important; /* 131.25% */
  letter-spacing: 0.067vw !important;
}

.text-hero {
  width: 52.361vw;
  text-align: justify;
}

.button-hero {
  display: flex;
  padding: 1.111vw 2.778vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.694vw !important;
  font-weight: 900 !important;
}
