.use-cases-mobile {
  padding: 0 6.15%;
  width: 100%;
  margin-top: 8vw;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 6.154vw;
}

.use-case-image-mobile {
  width: 87.692vw;
}

.use-cases-title-mobile {
  text-align: center;
}

.card-use-cases-mobile {
  position: relative;
}

.text-use-cases-mobile {
  position: absolute;
  left: 27.4%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.electric-use-case-mobile {
  top: 23%;
  gap: 18.462vw;
}

.water-gas-use-case-mobile {
  top: 18.66%;
  gap: 14.851vw;
}

.water-gas-text-mobile {
  line-height: 7vw !important;
}

.more-products-color-mobile,
.more-products-color-mobile:hover,
.more-products-color-mobile:active,
.more-products-color-mobile:visited {
  color: #cfd0d1 !important;
}

.more-products-color-line-mobile,
.more-products-color-line-mobile:hover,
.more-products-color-line-mobile:active,
.more-products-color-line-mobile:visited {
  background: #cfd0d1 !important;
}
