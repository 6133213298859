.awards-tablet {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 5vw 0;
}

.awards-content-tablet {
  display: flex;
  width: 94.245vw;
  padding: 4.796vw 3.837vw;
  flex-direction: column;
  align-items: center;
  gap: 3.837vw;
  border-radius: 0.6vw;
}

.awards-text-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
}

.awards-list-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  margin: 0;
  padding-left: 3vw;
}

.awards-list-item-tablet {
  text-align: justify;
}

.awards-image-tablet {
  width: 70.504vw;
}
