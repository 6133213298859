.technical-features-products-water-gas-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
}

.table-products-water-gas-mobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.533vw;
}

.section-table-products-water-gas-mobile {
  display: flex;
  align-items: flex-start;
  gap: 0.533vw;
  align-self: stretch;
}

.section-name-table-products-water-gas-mobile {
  display: flex;
  width: 35.897vw;
  padding: 2.051vw 4.103vw;
  justify-content: center;
  align-items: center;
  gap: 2.051vw;
  align-self: stretch;
  text-align: center;
}

.row-container-table-products-water-gas-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.533vw;
  flex: 1 0 0;
  align-self: stretch;
}

.row-table-products-water-gas-mobile {
  display: flex;
  padding: 2.051vw;
  align-items: center;
  gap: 2.051vw;
  align-self: stretch;
  flex: 1 0 0;
}

.blue-50 {
  background-color: #96c1c680;
}

.blue-25 {
  background-color: #96c1c640;
}

.grey-50 {
  background-color: #cfd0d180;
}

.grey-25 {
  background-color: #cfd0d140;
}
