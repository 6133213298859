.why-part-1-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 4vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  text-align: justify;
}

.content-part-1-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
}

.content-figure-1-desktop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.text-figure-1-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  width: 36.806vw;
}

.list-figure-1-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
}

.figure-1-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.image-figure-1-desktop {
  width: 43.611vw;
}

.text-gray-80 {
  color: #414147;
}
