.commitment-products-power-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 4.5vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.333vw;
}

.benefits-list-products-power-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  align-self: stretch;
}

.benefit-products-power-desktop {
  display: flex;
  align-items: center;
  gap: 1.111vw;
  align-self: stretch;
}

.image-benefit-ptoducts-power-desktop {
  width: 3.333vw;
}
