.hero-legal-mentions-tablet {
  position: relative;
}

.image-hero-legal-mentions-tablet {
  width: 100%;
}

.text-box-hero-legal-mentions-tablet {
  position: absolute;
  left: 6.71%;
  top: 64.28%;
}
