.awards-desktop {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4vw 0;
}

.awards-content-desktop {
  display: inline-flex;
  padding: 3.889vw;
  align-items: flex-end;
  gap: 2vw;
  border-radius: 0.347vw;
}

.awards-text-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
}

.awards-list-desktop {
  display: flex;
  width: 40vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2vw;
  margin: 0;
  padding-left: 1.5vw;
}

.awards-list-item-desktop {
  text-align: justify;
}

.awards-image-desktop {
  width: 40.833vw;
}
