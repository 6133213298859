.hero-legal-mentions-desktop {
  position: relative;
}

.image-hero-legal-mentions-desktop {
  width: 100%;
}

.text-box-hero-legal-mentions-desktop {
  position: absolute;
  left: 8.33%;
  top: 60.3%;
}
