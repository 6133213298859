.use-cases-tablet {
  padding: 0 6.71%;
  width: 100%;
  margin-top: 4vw;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3.837vw;
}

.use-case-image-tablet {
  width: 62vw;
}

.card-use-cases-tablet {
  position: relative;
}

.text-use-cases-tablet {
  position: absolute;
  left: 27.4%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.electric-use-case-tablet {
  top: 25.33%;
  gap: 12.24vw;
}

.water-gas-use-case-tablet {
  top: 18.66%;
  gap: 10.5vw;
}

.water-gas-text-tablet {
  line-height: 4.796vw !important;
}

.more-products-color-tablet,
.more-products-color-tablet:hover,
.more-products-color-tablet:active,
.more-products-color-tablet:visited {
  color: #cfd0d1 !important;
}

.more-products-color-line-tablet,
.more-products-color-line-tablet:hover,
.more-products-color-line-tablet:active,
.more-products-color-line-tablet:visited {
  background: #cfd0d1 !important;
}
