.hero-mobile {
  position: relative;
}

.image-hero-mobile {
  width: 100%;
}

.text-box-hero-mobile {
  position: absolute;
  left: 0;
  top: 16.03%;
  display: flex;
  width: 100%;
  padding: 0 6.15%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.205vw;
}

.title-box-hero-mobile {
  display: flex;
  flex-direction: column;
  gap: 4.103vw;
}

.title-hero-mobile {
  line-height: 7.692vw !important; /* 135.714% */
}

@supports (-moz-appearance: none) {
  .title-hero-mobile {
    letter-spacing: -0.15vw;
  }
}

.text-hero-mobile {
  width: 100%;
  text-align: justify;
}

.button-hero-mobile {
  display: flex;
  padding: 3.077vw 8.205vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2.564vw !important;
  font-weight: 900 !important;
  margin: auto;
}
