.hero-legal-mentions-mobile {
  position: relative;
}

.image-hero-legal-mentions-mobile {
  width: 100%;
}

.text-box-hero-legal-mentions-mobile {
  position: absolute;
  left: 6.15%;
  top: 64.15%;
}
