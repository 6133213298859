.solution-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 6vw;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3vw;
}

.solution-content-desktop {
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
  text-align: justify;
}

.solution-levels-img-desktop {
  width: 73vw;
  margin: auto;
}

.solution-steps-img-desktop {
  width: 67.778vw;
  margin: auto;
}

.text-read-more {
  display: flex;
  align-items: center;
}

.no-line-height {
  line-height: 1.389vw !important;
  margin-bottom: 0 !important;
}

.arrow-read-more {
  width: 1.667vw;
  height: 1.667vw;
}

.line-read-more {
  width: 102%;
  height: 0.174vw;
  background: #96c1c6;
  border-radius: 0.174vw;
}

.read-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.read-more:visited,
.read-more:hover,
.read-more:active {
  text-decoration: none !important;
}
