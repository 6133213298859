.contact-products-power-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin: 4.5vw 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.667vw;
}

.button-products-power-desktop {
  display: flex;
  padding: 1.111vw 2.778vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.694vw !important;
  font-weight: 900 !important;
}
