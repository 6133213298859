.why-part-3-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 3.837vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7vw;
  text-align: justify;
}

.content-part-3-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.103vw;
  width: 100%;
}

.figure-3-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
  margin: 4.103vw auto;
}

.image-figure-3-mobile {
  width: 87.692vw;
}

.description-figure-3-mobile {
  width: 87.692vw;
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
