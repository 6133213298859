.support-mobile {
  padding: 0 6.15%;
  width: 100%;
  margin-top: 8vw;
  margin-bottom: 8vw;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
}

.support-title-mobile {
  gap: 2vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.support-divider-mobile {
  width: 100%;
  height: 0.641vw;
  background: #96c1c6;
  border-radius: 0.641vw;
}

.logos-container-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.logo-image-1-mobile {
  height: 19.231vw;
}

.logo-image-2-mobile {
  height: 10.962vw;
}

.logo-image-3-mobile {
  height: 14.039vw;
}

.logo-image-4-mobile {
  height: 13.269vw;
}

.logo-image-5-mobile {
  height: 19.231vw;
}

.logo-image-6-mobile {
  height: 15.385vw;
}
