.commitment-products-water-gas-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 10vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
}

.benefits-list-products-water-gas-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.5vw;
  align-self: stretch;
}

.benefit-products-water-gas-mobile {
  display: flex;
  align-items: center;
  gap: 3.077vw;
  align-self: stretch;
}

.image-benefit-ptoducts-water-gas-mobile {
  width: 9.231vw;
}
