.products-section-power-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 2.878vw;
  margin-bottom: 6vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.755vw;
}

.products-description-power-tablet {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
  text-align: justify;
}

.product-description-power-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.878vw;
  align-self: stretch;
}

.products-images-power-tablet {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4.796vw;
}

.product-image-power-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.918vw;
}

.image-power-tablet {
  width: 40.767vw;
}

.description-image-power-tablet {
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
