.commitment-products-water-gas-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 6vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
}

.benefits-list-products-water-gas-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  align-self: stretch;
}

.benefit-products-water-gas-tablet {
  display: flex;
  align-items: center;
  gap: 1.918vw;
  align-self: stretch;
}

.image-benefit-ptoducts-water-gas-tablet {
  width: 4.796vw;
}
