.step-2-desktop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6.244vw;
  margin-left: auto;
  width: 100%;
}

.step-2-text-desktop {
  display: flex;
  width: 50%;
  padding: 6.8vw 8.33% 6.8vw 5.489vw;
  flex-direction: column;
  align-items: flex-end;
  gap: 2.222vw;
  text-align: justify;
}

.step-2-title-desktop {
  display: flex;
  align-items: center;
  gap: 0.833vw;
}

.step-2-text-content-desktop {
  width: 33.75vw;
}

.figure-5-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.video-figure-5-desktop {
  width: 38.194vw;
}

.text-gray-80 {
  color: #414147;
}
