.use-cases {
  padding-left: 8.33%;
  padding-right: 8.33%;
  width: 100%;
  margin-top: 4vw;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
}

.use-cases-container {
  display: flex;
  align-items: center;
  gap: 8vw;
}

.use-case-image {
  width: 34vw;
}

.card-use-cases {
  position: relative;
}

.text-use-cases {
  position: absolute;
  left: 27.4%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.electric-use-case {
  top: 25.33%;
  gap: 7vw;
}

.water-gas-use-case {
  top: 18.66%;
  gap: 5vw;
}

.water-gas-text {
  line-height: 2.778vw !important;
}

.text-read-more {
  display: flex;
  align-items: center;
}

.no-line-height {
  line-height: 1.389vw !important;
  margin-bottom: 0 !important;
}

.arrow-read-more {
  width: 1.667vw;
  height: 1.667vw;
}

.line-read-more {
  width: 102%;
  height: 0.174vw;
  background: #96c1c6;
  border-radius: 0.174vw;
}

.read-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.read-more:visited,
.read-more:hover,
.read-more:active {
  text-decoration: none !important;
}

.more-products-color,
.more-products-color:hover,
.more-products-color:active,
.more-products-color:visited {
  color: #cfd0d1 !important;
}

.more-products-color-line,
.more-products-color-line:hover,
.more-products-color-line:active,
.more-products-color-line:visited {
  background: #cfd0d1 !important;
}
