.posts-container-mobile {
  padding: 4vw 6.15%;
  margin-top: 10vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
  height: 100%;
  background-image: url('../assets/wave_pattern.svg');
}

.news-text-mobile {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
}

.news-paragprah-mobile {
  text-align: justify;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vw;
}

.posts-carousel-mobile {
  width: 86vw;
  padding: 2.5vw 2vw;
  margin: auto;
  border-radius: 2.564vw;
}

.post-mobile {
  display: inline-flex;
  padding: 2.564vw 2.564vw 6.154vw 2.564vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.564vw;
  border-radius: 1.282vw;
  width: auto;
}

.post-image-mobile {
  width: 76.923vw;
  height: 76.923vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-date-mobile {
  margin: 0 !important;
}

.post-title-mobile {
  width: 76.923vw;
  margin: 0 !important;
  text-align: justify;
}

.post-description-mobile {
  width: 76.923vw;
  margin: 0 !important;
  text-align: justify;
}

.car-img-mobile {
  width: 100%;
}

.car-img-zoom-mobile {
  width: 120%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.car-img-zoom-zoom-mobile {
  width: 170%;
  margin-left: 70%;
  transform: translateX(-70%);
}

.posts-carousel-mobile .carousel-indicators [data-bs-target] {
  width: 4vw !important;
  height: 4vw !important;
  border-radius: 4vw;
  margin: 4vw 1vw;
  background-color: #ffffff80;
  outline: none !important;
  border: none !important;
}

.posts-carousel-mobile .carousel-indicators .active {
  background-color: #ffffff;
  outline: none !important;
  border: none !important;
}

.posts-carousel-mobile .carousel-control-prev-icon {
  background-image: url('../assets/arrow_prev.svg') !important;
  width: 5.128vw !important;
  height: 9.371vw !important;
  position: absolute !important;
  left: -4vw !important;
}

.posts-carousel-mobile .carousel-control-next-icon {
  background-image: url('../assets/arrow_next.svg') !important;
  width: 5.128vw !important;
  height: 9.371vw !important;
  position: absolute !important;
  right: -4vw !important;
}

.visually-hidden {
  display: none !important;
}
