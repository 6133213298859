.about-text-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 4vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  text-align: justify;
}
