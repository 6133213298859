.step-3-desktop {
  display: flex;
  align-items: center;
  gap: 3.403vw;
  width: 100%;
}

.step-3-text-desktop {
  display: flex;
  width: 50%;
  padding: 2.222vw 5.489vw 2.222vw 8.33%;
  flex-direction: column;
  align-items: flex-end;
  gap: 2.222vw;
  text-align: justify;
}

.step-3-title-desktop {
  display: flex;
  align-items: center;
  gap: 0.833vw;
}

.step-3-text-content-desktop {
  width: 33.75vw;
  display: inline-flex;
  flex-direction: column;
  gap: 1vw;
}

.figure-6-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.video-figure-6-desktop {
  width: 38.194vw;
}

.text-gray-80 {
  color: #414147;
}
