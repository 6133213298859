.support {
  padding-left: 8.33%;
  padding-right: 8.33%;
  width: 100%;
  margin-top: 4vw;
  margin-bottom: 4vw;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
}

.support-title {
  gap: 1vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.support-divider {
  width: 100%;
  height: 0.174vw;
  background: #96c1c6;
  border-radius: 0.174vw;
}

.logos-container {
  display: flex;
  align-items: center;
  gap: 4vw;
}

.logo-image-1 {
  height: 7vw;
}

.logo-image-2 {
  height: 4vw;
}

.logo-image-3 {
  height: 5vw;
}

.logo-image-4 {
  height: 4.8vw;
}

.logo-image-5 {
  height: 7vw;
}

.logo-image-6 {
  height: 5.5vw;
}
