.technical-features-products-water-gas-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 4vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  width: 100%;
}

.table-products-water-gas-desktop {
  display: flex;
  width: 91.66%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.278vw;
}

.section-table-products-water-gas-desktop {
  display: flex;
  align-items: flex-start;
  gap: 0.278vw;
  align-self: stretch;
}

.section-name-table-products-water-gas-desktop {
  display: flex;
  width: 24.306vw;
  padding: 0.556vw 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.556vw;
  align-self: stretch;
}

.row-container-table-products-water-gas-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.278vw;
  flex: 1 0 0;
}

.row-table-products-water-gas-desktop {
  display: flex;
  padding: 0.556vw 1.667vw;
  align-items: center;
  gap: 0.556vw;
  align-self: stretch;
}

.blue-50 {
  background-color: #96c1c680;
}

.blue-25 {
  background-color: #96c1c640;
}

.grey-50 {
  background-color: #cfd0d180;
}

.grey-25 {
  background-color: #cfd0d140;
}
