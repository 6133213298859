.contact-products-power-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin: 12vw 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
}

.button-products-power-mobile {
  display: flex;
  padding: 3.077vw 8.205vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2.564vw !important;
  font-weight: 900 !important;
}
