.navbar-mobile {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 100 !important;
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
  padding: 12.308vw 6.15% !important;
}

.navbar-logo-img-mobile {
  height: 10.074vw;
}

.navbar-menu-button-mobile {
  height: 10.074vw;
}

.navbar-menu-button-cont-mobile {
  outline: none !important;
  border: 0 !important;
}

.navbar-menu-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  gap: 0.42vw;
  width: 100%;
  margin-left: auto;
  background-color: #181933 !important;
  color: #fff !important;
  padding: 4.27vw 0 !important;
  border-radius: 1.28vw !important;
  overflow: hidden !important;
}

.dropdown-mobile .dropdown-menu {
  background-color: #181933 !important;
  padding: 0 !important;
  border-radius: 0.42vw !important;
  border: none;
}

.dropdown-mobile .dropdown-toggle {
  color: #fff !important;
  padding: 2.13vw 6.41vw !important;
  overflow: hidden !important;
  width: 100%;
}

.dropdown-items-mobile {
  padding: 2.13vw 5vw !important;
  color: #fff !important;
  width: 100%;
  max-width: 100%;
}

.navbar-item-mobile,
.navbar-dropdown-item-mobile {
  padding: 2.13vw 8.55vw !important;
  width: 100%;
}

.navbar-dropdown-item-mobile .dropdown-toggle {
  padding-left: 0 !important;
}

.navbar-item-mobile:hover,
.dropdown-items-mobile:hover {
  background-color: #96c1c6 !important;
}

.navbar-item-active-mobile {
  background-color: #96c1c6 !important;
}
