.general-approach-risk-assessment-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 4vw;
  margin-bottom: 4vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  text-align: justify;
}

.risk-assessment-joint-content-desktop {
  width: 62vw;
  margin-bottom: -1.5vw;
}

.risk-assessment-list-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  margin: 1vw 0;
  padding-left: 0;
  text-align: justify;
}

.risk-assessment-figure-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vw;
  margin: 1vw 0;
}

.risk-assessment-figure-image-desktop {
  width: 46.389vw;
}
