.downloads-section-tablet {
  padding: 0 6.71%;
  margin: 6vw 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.878vw;
  text-align: justify;
}

.download-links-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.918vw;
}

.download-link-tablet {
  display: flex;
  align-items: center;
  gap: 0.959vw;
}

.download-link-tablet:hover {
  color: #181933;
}

.download-icon-tablet {
  width: 3.837vw;
}
