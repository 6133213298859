.navbar-desktop {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 100 !important;
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
  padding: 3vw 8.33% !important;
}

.navbar-logo-img-desktop {
  width: 10vw;
}

.navbar-menu-desktop {
  display: flex;
  align-items: center;
  margin: auto;
  gap: 0.2vw;
}

.dropdown-desktop .dropdown-menu {
  background-color: #181933e6 !important;
}

.dropdown-desktop {
  border-radius: 0.4vw !important;
}

.dropdown-desktop .dropdown-toggle {
  color: #fff !important;
  padding: 0.7vw 0.8vw !important;
  border-radius: 0.4vw !important;
  overflow: hidden !important;
}

.dropdown-items-desktop {
  padding: 0.7vw 2vw !important;
  color: #fff !important;
}

.dropdown-desktop .dropdown-toggle::after {
  display: none !important;
}

.navbar-item-desktop {
  border-radius: 0.4vw;
  padding: 0.7vw 0.8vw !important;
}

.navbar-item-desktop:hover,
.navbar-dropdown-item-desktop:hover,
.dropdown-items-desktop:hover,
.navbar-item-active-desktop {
  background-color: #96c1c6cc !important;
}
