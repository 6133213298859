.posts-container {
  padding: 1% 8.33%;
  margin-top: 6vw;
  display: flex;
  width: 100%;
  background-image: url('../assets/wave_pattern.svg');
  background-size: 120%;
}

.news-text {
  padding-top: 10%;
  width: 35vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6vw;
}

.news-paragprah {
  text-align: justify;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
}

.posts-carousel-desktop {
  width: 43vw;
  background-color: #ffffff4d;
  padding: 1.3vw 2vw;
  margin-left: auto;
  border-radius: 0.694vw;
}

.post {
  display: inline-flex;
  padding: 1.667vw 1.389vw 2.778vw 1.389vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  border-radius: 0.347vw;
  width: auto;
}

.post-image {
  width: 35vw;
  height: 35vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-date {
  margin: 0 !important;
}

.post-title {
  width: 35vw;
  margin: 0 !important;
  text-align: justify;
}

.post-description {
  width: 35vw;
  margin: 0 !important;
  text-align: justify;
}

.car-img {
  width: 100%;
}

.car-img-zoom {
  width: 120%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.car-img-zoom-zoom {
  width: 170%;
  margin-left: 70%;
  transform: translateX(-70%);
}

.posts-carousel-desktop .carousel-indicators [data-bs-target] {
  width: 1vw !important;
  height: 1vw !important;
  border: 1px solid transparent !important;
  border-radius: 1vw;
  margin: 1.5vw 0.2vw;
  background-color: #ffffff80;
  outline: none !important;
  border: none !important;
}

.posts-carousel-desktop .carousel-indicators .active {
  background-color: #ffffff;
  outline: none !important;
  border: none !important;
}

.posts-carousel-desktop .carousel-control-prev-icon {
  background-image: url('../assets/arrow_prev.svg') !important;
  width: 2.28vw !important;
  height: 4.167vw !important;
  position: absolute !important;
  left: -1vw !important;
}

.posts-carousel-desktop .carousel-control-next-icon {
  background-image: url('../assets/arrow_next.svg') !important;
  width: 2.28vw !important;
  height: 4.167vw !important;
  position: absolute !important;
  right: -1vw !important;
}

.visually-hidden {
  display: none !important;
}
