.footer-mobile {
  width: 100%;
  position: relative;
  margin-top: auto;
}

.footer-img-mobile {
  width: 100%;
}

.footer-content-mobile {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  left: 6.15%;
  top: 7.55%;
  width: 87.7%;
  gap: 8.205vw;
}

.footer-logo-img-mobile {
  width: 25.641vw;
}

.footer-middle-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.205vw;
  margin: auto;
}

.footer-menu-mobile {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 16.41vw;
}

.footer-menu-column-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
}

.footer-connect-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.103vw;
}

.footer-email-phone-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
}

.social-media-logos-mobile {
  display: flex;
  align-items: flex-start;
  gap: 2vw;
}

.social-media-logo-mobile {
  width: 8.205vw;
}

.social-media-logo-mobile a {
  padding: 0 !important;
}

.footer-copyright-mobile {
  position: absolute;
  display: flex;
  align-items: flex-start;
  left: 6.15%;
  bottom: 7%;
  color: #a0a0a3;
}
