.about-text-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 2vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  text-align: justify;
}
