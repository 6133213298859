.step-3-mobile {
  margin-top: 6.154vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6.154vw;
}

.step-3-text-mobile {
  display: flex;
  width: 100%;
  padding: 8.205vw 6.154vw;
  flex-direction: column;
  align-items: flex-end;
  gap: 4.103vw;
  text-align: justify;
}

.step-3-title-mobile {
  display: flex;
  align-items: center;
  gap: 1.272vw;
  width: 100%;
}

.step-3-text-content-mobile {
  width: 82.051vw;
  display: inline-flex;
  flex-direction: column;
  gap: 2vw;
}

.figure-6-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
}

.video-figure-6-mobile {
  width: 87.692vw;
}

.description-figure-6-mobile {
  width: 87.692vw;
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
