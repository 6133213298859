.contact-products-water-gas-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin: 6.5vw 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.878vw;
}

.button-products-water-gas-tablet {
  display: flex;
  padding: 1.918vw 4.796vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1.199vw !important;
  font-weight: 900 !important;
}
