.hero-actis-tablet {
  position: relative;
}

.image-hero-actis-tablet {
  width: 100%;
}

.text-box-hero-actis-tablet {
  position: absolute;
  left: 6.71%;
  top: 47.36%;
  display: flex;
  width: 86.58%;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
}

.text-hero-actis-tablet {
  text-align: justify;
}
