.posts-container-tablet {
  padding: 5vw 6.71%;
  margin-top: 6vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vw;
  height: 100%;
  background-image: url('../assets/wave_pattern.svg');
}

.news-text-tablet {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.878vw;
}

.news-paragprah-tablet {
  text-align: justify;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2vw;
}

.posts-carousel-tablet {
  width: 75vw;
  background-color: #ffffff4d;
  padding: 1.3vw 2vw;
  margin: auto;
  border-radius: 1.199vw;
}

.post-tablet {
  display: inline-flex;
  padding: 2.878vw 2.398vw 4.796vw 2.398vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.918vw;
  border-radius: 0.6vw;
  width: auto;
}

.post-image-tablet {
  width: 59.952vw;
  height: 59.952vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-date-tablet {
  margin: 0 !important;
}

.post-title-tablet {
  width: 59.952vw;
  margin: 0 !important;
  text-align: justify;
}

.post-description-tablet {
  width: 59.952vw;
  margin: 0 !important;
  text-align: justify;
}

.car-img-tablet {
  width: 100%;
}

.car-img-zoom-tablet {
  width: 120%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.car-img-zoom-zoom-tablet {
  width: 170%;
  margin-left: 70%;
  transform: translateX(-70%);
}

.posts-carousel-tablet .carousel-indicators [data-bs-target] {
  width: 2vw !important;
  height: 2vw !important;
  border-radius: 2vw;
  margin: 3vw 0.5vw;
  background-color: #ffffff80;
  outline: none !important;
  border: none !important;
}

.posts-carousel-tablet .carousel-indicators .active {
  background-color: #ffffff;
  outline: none !important;
  border: none !important;
}

.posts-carousel-tablet .carousel-control-prev-icon {
  background-image: url('../assets/arrow_prev.svg') !important;
  width: 3.937vw !important;
  height: 3.75rem !important;
  position: absolute !important;
  left: -2vw !important;
}

.posts-carousel-tablet .carousel-control-next-icon {
  background-image: url('../assets/arrow_next.svg') !important;
  width: 3.937vw !important;
  height: 3.75rem !important;
  position: absolute !important;
  right: -2vw !important;
}

.visually-hidden {
  display: none !important;
}
