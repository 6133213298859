.footer-desktop {
  width: 100%;
  position: relative;
  margin-top: auto;
}

.footer-img-desktop {
  width: 100%;
}

.footer-content-desktop {
  position: absolute;
  display: flex;
  align-items: flex-start;
  left: 8.33%;
  top: 20.5%;
  width: 83.33%;
}

.footer-logo-img-desktop {
  width: 9vw;
}

.footer-menu-desktop {
  display: flex;
  align-items: flex-start;
  gap: 7vw;
  width: fit-content;
  margin: 0 auto;
}

.footer-menu-column-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5vw;
}

.footer-connect-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
}

.footer-email-phone-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5vw;
}

.social-media-logos-desktop {
  display: flex;
  align-items: flex-start;
  gap: 0.5vw;
}

.social-media-logo-desktop {
  width: 2.5vw;
}

.social-media-logo-desktop a {
  padding: 0 !;
}

.footer-copyright-desktop {
  position: absolute;
  display: flex;
  align-items: flex-start;
  left: 8.33%;
  bottom: 10%;
  color: #a0a0a3;
}
