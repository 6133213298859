.technical-features-products-power-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.878vw;
}

.table-products-power-tablet {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.48vw;
}

.section-table-products-power-tablet {
  display: flex;
  align-items: flex-start;
  gap: 0.48vw;
  align-self: stretch;
}

.section-name-table-products-power-tablet {
  display: flex;
  width: 29.976vw;
  padding: 0.959vw 0;
  justify-content: center;
  align-items: center;
  gap: 0.959vw;
  align-self: stretch;
  text-align: center;
}

.row-container-table-products-power-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.48vw;
  flex: 1 0 0;
}

.row-table-products-power-tablet {
  display: flex;
  padding: 0.959vw 2.878vw;
  align-items: center;
  gap: 0.959vw;
  align-self: stretch;
}

.blue-50 {
  background-color: #96c1c680;
}

.blue-25 {
  background-color: #96c1c640;
}

.grey-50 {
  background-color: #cfd0d180;
}

.grey-25 {
  background-color: #cfd0d140;
}
