.contact-info-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 6vw;
  margin-bottom: 6vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
}

.contact-element-tablet {
  display: flex;
  align-items: center;
  gap: 2vw;
}

.contact-element-image-tablet {
  width: 5vw;
}

.contact-element-divider-tablet {
  align-self: stretch;
  border-left: 0.3vw solid #96c1c6;
}

.contact-location-element-tablet {
  display: flex;
  align-items: flex-start;
  gap: 2vw;
}

.address-and-location-tablet {
  display: flex;
  align-items: flex-start;
  gap: 5vw;
}

.address-text-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  margin-top: 0.7vw;
}

.map-location-tablet {
  border: 0;
  width: 25vw;
  height: 25vw;
}
