.downloads-section-mobile {
  padding: 0 6.71%;
  margin: 8vw 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.103vw;
  text-align: justify;
}

.download-links-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
}

.download-link-mobile {
  display: flex;
  align-items: center;
  gap: 2.051vw;
}

.download-link-mobile:hover {
  color: #181933;
}

.download-icon-mobile {
  width: 6.154vw;
}
