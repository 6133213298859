.general-approach-detection-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 3vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  text-align: justify;
}

.network-type-container-desktop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.network-type-desktop {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detection-content-desktop {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.detection-list-desktop {
  display: flex;
  width: 38.889vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  margin: 1vw 0;
  padding-left: 1.5vw;
}

.detection-element-divider-desktop {
  align-self: stretch;
  border-left: 0.174vw solid #96c1c6;
  border-right: 0.174vw solid #96c1c6;
}

.detection-figure-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.detection-images-container-desktop {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}

.detection-figure-image-desktop {
  width: 20.833vw;
}

.detection-figure-description-desktop {
  text-align: center;
}

.detection-figure-container-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.5vw;
}

.text-gray-80 {
  color: #414147;
}
