.contact-info-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
}

.contact-element-mobile {
  display: flex;
  align-items: center;
  gap: 4vw;
}

.contact-element-image-mobile {
  width: 8.5vw;
}

.contact-element-divider-mobile {
  align-self: stretch;
  border-left: 0.641vw solid #96c1c6;
}

.contact-location-element-mobile {
  display: flex;
  align-items: flex-start;
  gap: 4vw;
}

.address-and-location-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5vw;
  margin-bottom: 1vw;
}

.address-text-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  margin-top: 1vw;
}

.map-location-mobile {
  border: 0;
  width: 56.41vw;
  height: 56.41vw;
}
