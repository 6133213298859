.auth-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
}

.label-text-auth {
  margin-left: 1.3vw !important;
}

.auth-password-container {
  display: flex;
  align-items: center;
  position: relative;
}

.secret-code-input {
  width: 45vw;
  display: flex;
  padding: 1.2vw 1.5vw !important;
  justify-content: center;
  align-items: center;
  border: 0.2vw solid #ccc;
  border-radius: 0.694vw !important;
}

.secret-code-button {
  display: flex;
  padding: 0.8vw 1.2vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.694vw !important;
  font-weight: 500 !important;
  margin: 0 !important;
  position: absolute;
  right: 0.4vw;
}

.auth-visibility-button {
  position: absolute;
  margin: 0;
  opacity: 70%;
  right: 9vw;
  margin: 0 !important;
  border: none;
  background-color: transparent;
}

.auth-visibility-icon {
  width: 2.2vw;
}

textarea:focus,
input:focus {
  outline: none;
}

.auth-visibility-button:focus,
.auth-visibility-button:active {
  outline: none;
}
