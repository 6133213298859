.awards-mobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 8vw 0;
}

.awards-content-mobile {
  display: flex;
  width: 100%;
  padding: 6.154vw 6.15%;
  flex-direction: column;
  align-items: center;
  gap: 6.154vw;
}

.awards-text-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
}

.awards-list-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vw;
  margin: 0;
  padding-left: 5vw;
}

.awards-list-item-mobile {
  text-align: justify;
}

.awards-image-mobile {
  width: 100%;
}
