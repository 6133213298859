.why-part-2-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
  text-align: justify;
}

.content-part-2-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.103vw;
  width: 100%;
}

.list-figure-2-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  width: 100%;
  padding-left: 5vw;
}

ol > li::marker {
  font-weight: bold;
}

.figure-2-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
  margin: 4.103vw auto;
}

.video-figure-2-mobile {
  width: 87.692vw;
}

.description-figure-2-mobile {
  width: 87.692vw;
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
