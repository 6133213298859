.hero-about-mobile {
  position: relative;
}

.image-hero-about-mobile {
  width: 100%;
}

.text-box-hero-about-mobile {
  position: absolute;
  left: 6.15%;
  top: 64.15%;
}
