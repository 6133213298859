.downloads-section-desktop {
  padding: 0 8.33%;
  margin: 5.556vw 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  text-align: justify;
}

.download-links-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.111vw;
}

.download-link-desktop {
  display: flex;
  align-items: center;
  gap: 0.556vw;
}

.download-link-desktop:hover {
  color: #181933;
}

.download-icon-desktop {
  width: 2.222vw;
}
