.hero-contact-tablet {
  position: relative;
}

.image-hero-contact-tablet {
  width: 100%;
}

.text-box-hero-contact-tablet {
  position: absolute;
  left: 6.71%;
  top: 43.9%;
  display: flex;
  width: 86.58%;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
}

.text-hero-contact-tablet {
  text-align: justify;
}
