.hero-products-water-gas-desktop {
  position: relative;
}

.image-hero-products-water-gas-desktop {
  width: 100%;
}

.text-box-hero-products-water-gas-desktop {
  position: absolute;
  left: 8.33%;
  top: 45%;
  display: flex;
  width: 83.34%;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.333vw;
}

.text-hero-products-water-gas-desktop {
  text-align: justify;
}
