.legal-mentions-content-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 4vw;
  margin-bottom: 4vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  text-align: justify;
}

.legal-mentions-section-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
}
