.text-gray-80 {
  color: #414147;
}

.team-desktop {
  width: 100%;
  padding-left: 8.33%;
  padding-right: 8.33%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.333vw;
  margin-bottom: 4vw;
}

.team-member-desktop {
  display: flex;
  align-items: center;
  gap: 3.889vw;
  width: 100%;
}

.team-member-image-desktop {
  width: 20.833vw;
}

.team-member-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.667vw;
}

.team-member-title-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.556vw;
}

.team-member-achivements-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3vw;
  padding-left: 2vw;
}

.member-achivement-desktop {
  text-align: justify;
}
