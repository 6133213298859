.about-text-tablet {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 6vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  text-align: justify;
}
