.why-part-1-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 7vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.154vw;
  text-align: justify;
}

.content-part-1-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.103vw;
  width: 100%;
}

.list-figure-1-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  width: 100%;
  padding-left: 5vw;
}

.figure-1-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
  margin: 4.103vw -3.85%;
}

.image-figure-1-mobile {
  width: 95.59vw;
}

.description-figure-1-mobile {
  width: 95.59vw;
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
