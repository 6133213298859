.general-approach-localization-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 4vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  text-align: justify;
}

.localization-joint-content-desktop {
  text-align: start;
  width: 62vw;
  margin-bottom: -1.5vw;
  padding-left: 0;
}

.localization-content-desktop {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.localization-list-desktop {
  display: flex;
  width: 38.889vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  margin: 1vw 0;
  padding-left: 1.5vw;
}

.localization-element-divider-desktop {
  align-self: stretch;
  border-left: 0.174vw solid #96c1c6;
  border-right: 0.174vw solid #96c1c6;
}
