.general-approach-detection-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 5vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  text-align: justify;
}

.detection-content-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  align-self: stretch;
}

.detection-content-title-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  align-self: stretch;
}

.detection-list-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  padding-left: 3.5vw;
}

.detection-element-divider-tablet {
  width: 100%;
  height: 0.3vw;
  background: #96c1c6;
}

.detection-figure-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.detection-images-container-tablet {
  display: flex;
  flex-direction: column;
  gap: 3.837vw;
}

.detection-figure-image-tablet {
  width: 33.957vw;
}

.detection-figure-description-tablet {
  text-align: center;
}

.detection-figure-container-tablet {
  display: flex;
  align-items: flex-start;
  gap: 5.755vw;
}

.text-gray-80 {
  color: #414147;
}
