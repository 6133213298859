.products-section-water-gas-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 6.154vw;
  margin-bottom: 12vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8vw;
}

.products-description-water-gas-mobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 7vw;
  text-align: justify;
}

.product-description-water-gas-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.103vw;
  align-self: stretch;
}

.products-images-water-gas-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.256vw;
}

.product-image-water-gas-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4.103vw;
}

.image-water-gas-mobile {
  width: 87.692vw;
}

.description-image-water-gas-mobile {
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
