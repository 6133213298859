.why-part-3-desktop {
  padding-left: 8.33%;
  padding-right: 8.33%;
  margin-top: 3vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5vw;
  text-align: justify;
}

.content-part-3-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5vw;
}

.figure-3-desktop {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
}

.image-figure-3-desktop {
  width: 52.083vw;
}

.text-gray-80 {
  color: #414147;
}
