.text-gray-80 {
  color: #414147;
}

.team-tablet {
  width: 100%;
  padding-left: 6.71%;
  padding-right: 6.71%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.837vw;
  margin-bottom: 5vw;
}

.team-member-tablet {
  display: flex;
  align-items: flex-start;
  gap: 2.878vw;
  width: 100%;
}

.team-member-image-tablet {
  width: 29.976vw;
}

.team-member-description-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
}

.team-member-title-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.959vw;
}

.team-member-achivements-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5vw;
  padding-left: 4vw;
}

.member-achivement-tablet {
  text-align: justify;
}
