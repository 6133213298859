/* Typography */

/* Headings/H1 40px|44px */
.h1 {
  font-size: 2.778vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.056vw; /* 110% */
  text-transform: uppercase;
}

.h1-m {
  font-size: 4.796vw;
  font-style: normal;
  font-weight: 700;
  line-height: 5.276vw; /* 110% */
  text-transform: uppercase;
}

.h1-s {
  font-size: 10.256vw;
  font-style: normal;
  font-weight: 700;
  line-height: 11.282vw; /* 110% */
  text-transform: uppercase;
}

/* Headings/H2 32px|36px */
.h2 {
  font-size: 2.222vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5vw; /* 112.5% */
  text-transform: uppercase;
}

.h2-m {
  font-size: 3.837vw;
  font-style: normal;
  font-weight: 700;
  line-height: 4.317vw; /* 112.5% */
  text-transform: uppercase;
}

.h2-s {
  font-size: 8.205vw;
  font-style: normal;
  font-weight: 700;
  line-height: 9.231vw; /* 112.5% */
  text-transform: uppercase;
}

/* Headings/H3 28px|32px */
.h3 {
  font-size: 1.944vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.222vw; /* 114.286% */
  text-transform: uppercase;
}

.h3-m {
  font-size: 3.357vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.837vw; /* 114.286% */
  text-transform: uppercase;
}

.h3-s {
  font-size: 7.179vw;
  font-style: normal;
  font-weight: 700;
  line-height: 8.205vw; /* 114.286% */
  text-transform: uppercase;
}

/* Headings/H4 24px|28px */
.h4 {
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.944vw; /* 116.667% */
  text-transform: uppercase;
}

.h4-m {
  font-size: 2.878vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.357vw; /* 116.667% */
  text-transform: uppercase;
}

.h4-s {
  font-size: 6.154vw;
  font-style: normal;
  font-weight: 700;
  line-height: 7.179vw; /* 116.667% */
  text-transform: uppercase;
}

/* Headings/H5 20px|24px */
.h5 {
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.667vw; /* 120% */
  text-transform: uppercase;
}

.h5-m {
  font-size: 2.398vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.878vw; /* 120% */
  text-transform: uppercase;
}

.h5-s {
  font-size: 5.128vw;
  font-style: normal;
  font-weight: 700;
  line-height: 6.154vw; /* 120% */
  text-transform: uppercase;
}

/* Headings/H6 16px|20px */
.h6 {
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.389vw; /* 125% */
  text-transform: uppercase;
}

.h6-m {
  font-size: 1.918vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.398vw; /* 125% */
  text-transform: uppercase;
}

.h6-s {
  font-size: 4.103vw;
  font-style: normal;
  font-weight: 700;
  line-height: 5.128vw; /* 125% */
  text-transform: uppercase;
}

.h7-s {
  font-size: 3.59vw;
  font-style: normal;
  font-weight: 700;
  line-height: 4.615vw; /* 125% */
  text-transform: uppercase;
}

/* Subheadings/S1 32px|36px */
.s1 {
  font-size: 2.222vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vw; /* 112.5% */
}

.s1-m {
  font-size: 3.837vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4.317vw; /* 112.5% */
}

.s1-s {
  font-size: 8.205vw;
  font-style: normal;
  font-weight: 600;
  line-height: 9.231vw; /* 112.5% */
}

/* Subheadings/S2 28px|32px */
.s2 {
  font-size: 1.944vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.222vw; /* 114.286% */
}

.s2-m {
  font-size: 3.357vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.837vw; /* 114.286% */
}

.s2-s {
  font-size: 7.179vw;
  font-style: normal;
  font-weight: 600;
  line-height: 8.205vw; /* 114.286% */
}

/* Subheadings/S3 24px|28px */
.s3 {
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.944vw; /* 116.667% */
}

.s3-m {
  font-size: 2.878vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.357vw; /* 116.667% */
}

.s3-s {
  font-size: 6.154vw;
  font-style: normal;
  font-weight: 600;
  line-height: 7.179vw; /* 116.667% */
}

/* Subheadings/S4 20px|24px */
.s4 {
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.667vw; /* 120% */
}

.s4-m {
  font-size: 2.398vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.878vw; /* 120% */
}

.s4-s {
  font-size: 5.128vw;
  font-style: normal;
  font-weight: 600;
  line-height: 6.154vw; /* 120% */
}

/* Subheadings/S5 16px|20px */
.s5 {
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.389vw; /* 125% */
}

.s5-m {
  font-size: 1.918vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.398vw; /* 125% */
}

.s5-s {
  font-size: 4.103vw;
  font-style: normal;
  font-weight: 600;
  line-height: 5.128vw; /* 125% */
}

.s6-s {
  font-size: 3.59vw;
  font-style: normal;
  font-weight: 600;
  line-height: 5.128vw; /* 142.857% */
}

/* Text/Body Lg 20px|24px */
.text-lg {
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw; /* 120% */
}

.text-lg-m {
  font-size: 2.398vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.878vw; /* 120% */
}

.text-lg-s {
  font-size: 5.128vw;
  font-style: normal;
  font-weight: 400;
  line-height: 6.154vw; /* 120% */
}

/* Text/Body Reg 16px|20px */
.text-reg {
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.389vw; /* 125% */
}

.text-reg-m {
  font-size: 1.918vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.398vw; /* 125% */
}

.text-reg-s {
  font-size: 4.103vw;
  font-style: normal;
  font-weight: 400;
  line-height: 5.128vw; /* 125% */
}

/* Text/Body Sm 14px|18px */
.text-sm {
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25vw; /* 128.571% */
}

.text-sm-m {
  font-size: 1.679vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.158vw; /* 128.571% */
}

.text-sm-s {
  font-size: 3.59vw;
  font-style: normal;
  font-weight: 400;
  line-height: 4.615vw; /* 128.571% */
}

/* Text/Body Xs 12px|16px */
.text-xs {
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.111vw; /* 133.333% */
}

.text-xs-m {
  font-size: 1.439vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.918vw; /* 133.333% */
}

.text-xs-s {
  font-size: 3.077vw;
  font-style: normal;
  font-weight: 400;
  line-height: 4.103vw; /* 133.333% */
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0 !important;
}

body {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
}

html {
  margin: 0;
  padding: 0;
}
