.why-part-2-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 3.837vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.918vw;
  text-align: justify;
}

.content-part-2-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vw;
}

.list-figure-2-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.918vw;
}

ol > li::marker {
  font-weight: bold;
}

.figure-2-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.918vw;
  margin: 2vw auto;
}

.video-figure-2-tablet {
  width: 37.5rem;
}

.text-gray-80 {
  color: #414147;
}
