.legal-mentions-content-mobile {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 5vw;
  margin-bottom: 5vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vw;
  text-align: justify;
}

.legal-mentions-section-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
}
