.hero-about-tablet {
  position: relative;
}

.image-hero-about-tablet {
  width: 100%;
}

.text-box-hero-about-tablet {
  position: absolute;
  left: 6.71%;
  top: 64.28%;
}
