.hero-actis-mobile {
  position: relative;
}

.image-hero-actis-mobile {
  width: 100%;
}

.text-box-hero-actis-mobile {
  position: absolute;
  left: 6.15%;
  top: 44.73%;
  display: flex;
  width: 87.7%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.205vw;
}

.text-hero-actis-mobile {
  text-align: justify;
}
