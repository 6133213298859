.actis-content-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin: 5vw 0;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vw;
  text-align: justify;
}

.figure-gif-actis-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  margin: 1vw auto;
}

.video-actis-tablet {
  width: 100%;
}
