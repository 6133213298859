.step-2-tablet {
  margin-top: 3.837vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 3.837vw;
}

.step-2-text-tablet {
  display: flex;
  width: 100%;
  padding: 6.715vw;
  flex-direction: column;
  align-items: flex-end;
  gap: 3.837vw;
  text-align: justify;
}

.step-2-title-tablet {
  display: flex;
  align-items: center;
  gap: 2.918vw;
}

.step-2-text-content-tablet {
  width: 81.894vw;
}

.figure-5-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 2.918vw;
}

.video-figure-5-tablet {
  width: 71.942vw;
}

.text-gray-80 {
  color: #414147;
}
