.why-part-3-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 3.837vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vw;
  text-align: justify;
}

.content-part-3-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.918vw;
}

.figure-3-tablet {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.918vw;
  margin: 2vw auto;
}

.image-figure-3-tablet {
  width: 83.933vw;
}

.text-gray-80 {
  color: #414147;
}
