.general-approach-localization-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 5vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  text-align: justify;
}

.localization-joint-content-tablet {
  text-align: start;
  width: 100%;
}

.localization-content-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  align-self: stretch;
}

.localization-content-title-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  align-self: stretch;
}

.localization-list-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  padding-left: 3.5vw;
}

.localization-element-divider-tablet {
  width: 100%;
  height: 0.3vw;
  background: #96c1c6;
}
