.footer-tablet {
  width: 100%;
  position: relative;
  margin-top: auto;
}

.footer-img-tablet {
  width: 100%;
}

.footer-content-tablet {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  left: 6.71%;
  top: 10.81%;
  width: 86.58%;
  gap: 5.5vw;
}

.logo-and-contact-tablet {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.footer-logo-img-tablet {
  width: 11.99vw;
}

.footer-menu-tablet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.footer-menu-column-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
}

.footer-connect-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  margin-left: auto;
}

.footer-email-phone-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5vw;
}

.social-media-logos-tablet {
  display: flex;
  align-items: flex-start;
  gap: 1vw;
}

.social-media-logo-tablet {
  width: 3.837vw;
}

.social-media-logo-tablet a {
  padding: 0 !important;
}

.footer-copyright-tablet {
  position: absolute;
  display: flex;
  align-items: flex-start;
  left: 6.71%;
  bottom: 12%;
  color: #a0a0a3;
}
