.hero-translocator-desktop {
  position: relative;
}

.image-hero-translocator-desktop {
  width: 100%;
}

.text-box-hero-translocator-desktop {
  position: absolute;
  left: 8.33%;
  top: 47.82%;
  display: flex;
  width: 83.34%;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.333vw;
}

.text-hero-translocator-desktop {
  text-align: justify;
}
