.hero-tablet {
  position: relative;
}

.image-hero-tablet {
  width: 100%;
}

.text-box-hero-tablet {
  position: absolute;
  left: 0;
  top: 14.73%;
  display: flex;
  width: 100%;
  padding: 0 6.71%;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.755vw;
}

.title-box-hero-tablet {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 1.918vw;
}

.title-hero-tablet {
  line-height: 4.556vw !important; /* 135.714% */
  letter-spacing: 0.06vw !important;
}

.text-hero-tablet {
  width: 100%;
  text-align: justify;
}

.button-hero-tablet {
  display: flex;
  padding: 1.918vw 4.796vw !important;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1.199vw !important;
  font-weight: 900 !important;
}
