.general-approach-detection-mobile {
  padding-left: 6.15%;
  padding-right: 6.15%;
  margin-top: 5vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  text-align: justify;
}

.detection-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  align-self: stretch;
}

.detection-content-title-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  align-self: stretch;
}

.detection-list-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vw;
  padding-left: 3.5vw;
}

.detection-element-divider-mobile {
  width: 100%;
  height: 0.4vw;
  background: #96c1c6;
}

.detection-figure-mobile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vw;
}

.detection-images-container-mobile {
  display: flex;
  flex-direction: column;
  gap: 3.837vw;
}

.detection-figure-image-mobile {
  width: 76.923vw;
}

.detection-figure-description-mobile {
  text-align: center;
}

.text-gray-80 {
  color: #414147;
}
