.general-approach-risk-assessment-tablet {
  padding-left: 6.71%;
  padding-right: 6.71%;
  margin-top: 7vw;
  margin-bottom: 6vw;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  text-align: justify;
}

.risk-assessment-joint-content-tablet {
  text-align: start;
  width: 100%;
}

.risk-assessment-content-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vw;
  align-self: stretch;
}

.risk-assessment-content-title-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  align-self: stretch;
}

.risk-assessment-list-tablet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  padding-left: 3.5vw;
}

.risk-assessment-element-divider-tablet {
  width: 100%;
  height: 0.3vw;
  background: #96c1c6;
}

.risk-assessment-figure-image-tablet {
  width: 80.096vw;
  margin: auto;
}
